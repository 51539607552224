"use client";

import { useState, useEffect } from "react";
import { Copy, Check, AlertCircle, Server, Cpu } from "lucide-react";

export function Terminal() {
  const [scanStep, setScanStep] = useState(0);
  const [copied, setCopied] = useState(false);
  const scanSteps = [
    "Initializing SafeScanner...",
    "Scanning network for devices...",
    "Performing OS fingerprinting...",
    "Checking for known CVEs...",
    "Analyzing running processes...",
    "Identifying vulnerable services...",
    "Generating report...",
    "Scan complete! 🛡️",
  ];

  const scanResults = [
    {
      type: "CVE",
      icon: AlertCircle,
      text: "CVE-2023-1234: Critical vulnerability in OpenSSL",
    },
    {
      type: "OS",
      icon: Server,
      text: "Outdated OS version detected: Ubuntu 18.04 LTS",
    },
    {
      type: "Process",
      icon: Cpu,
      text: "Vulnerable process: apache2 (version 2.4.29)",
    },
    {
      type: "Device",
      icon: AlertCircle,
      text: "IoT Device: Unpatched firmware (v1.2.3)",
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setScanStep((prev) => (prev < scanSteps.length - 1 ? prev + 1 : prev));
    }, 1000);

    return () => clearTimeout(timer);
  }, [scanStep]);

  const copyToClipboard = () => {
    const fullReport = scanSteps
      .concat(scanResults.map((r) => `${r.type}: ${r.text}`))
      .join("\n");
    navigator.clipboard.writeText(fullReport);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="w-full rounded-lg shadow-lg overflow-hidden bg-gray-900 text-white font-mono text-sm relative">
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <div className="flex space-x-2">
            <div className="w-3 h-3 rounded-full bg-red-500"></div>
            <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
            <div className="w-3 h-3 rounded-full bg-green-500"></div>
          </div>
          <button
            onClick={copyToClipboard}
            className="text-gray-400 hover:text-white transition-colors"
            aria-label="Copy to clipboard"
          >
            {copied ? (
              <Check className="h-5 w-5" />
            ) : (
              <Copy className="h-5 w-5" />
            )}
          </button>
        </div>
        <div className="space-y-2">
          {scanSteps.map((step, index) => (
            <div
              key={index}
              className={`${
                index > scanStep ? "opacity-0" : "opacity-100"
              } transition-opacity duration-300`}
            >
              <span className="text-green-400">$</span> {step}
            </div>
          ))}
          {scanStep === scanSteps.length - 1 && (
            <div className="mt-4 space-y-2">
              <div className="text-yellow-300">Scan Results:</div>
              {scanResults.map((result, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <result.icon className="h-4 w-4 text-orange-500" />
                  <span>{result.text}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
